.agreement__content {
    padding-top:25px;
    padding-bottom:60px;
    padding-right: 70px;
    padding-left: 70px;
    background-color:#fff;
    min-width: 300px;
    max-width: 70%;
    margin: 50px auto auto;
}

.agreement__content h1, .agreement__content h3{
    text-align: center;
}

.agreement__content p {
    text-align: justify;
}
