.App {
  text-align: center;
}

.App-logo {
  margin-top: 40px;
  width: 217.49px;
  height: 40.03px;
  left: calc(50% - 217.49px/2 + 1.25px);
  top: calc(50% - 40.03px/2 - 0.35px);
  pointer-events: none;
}

.App-header {
  background-color: #F5F3F0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #48484A;
  font-family: 'SF Pro Display', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
