.RegistrationPage form {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.RegistrationPage button:disabled {
    background-color: #ccc;
    color: #666;
}

.RegistrationPage .success {
    color: green;
}

.RegistrationPage button {
    background: #007AFF;
    border-radius: 8px;
    padding: 12px 20px;
    border: none;
    color: #FFF;
    font-family: 'SF Pro Display', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    min-width: 310px;
}

.RegistrationPage input {
    background: #FFF;
    color: #000;
    border-radius: 8px;
    line-height: 24px;
    padding: 12px 20px;
    border: none;
    min-width: 270px;
}

.RegistrationPage input.error {
    border: 1px solid red;
    color: #000;
}

.RegistrationPage .error {
    color: red;
    min-height: 24px;
}

.RegistrationPage input::placeholder {
    color: rgba(72, 72, 74, 0.4);
}
