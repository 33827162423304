.home__feedback {
    padding-top:145px;
    padding-bottom:60px;
}

.home__feedback p {
    text-align: center;
}

.home__feedback iframe {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 602px;
}
